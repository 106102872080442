<template>
  <div class="behaviour-edit">
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("behaviour-create.header") }}
      </h3>

      <BehaviourForm :behaviour="behaviour" :onSubmit="onSubmit" />

      <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
        <vs-button color="primary" type="filled" @click="onSubmit()">
          {{ $l.get("dictionary.submit-new") }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/behaviour-create/submit-form";
import InitBehaviourCreateInteractor from "@/business/behaviour-create/init-behaviour-create";
import BehaviourCreateScreenController from "@/adapters/controllers/screen-behaviour-create";
import BehaviourForm from "@/application/components/behaviours/BehaviourForm";
import Loader from "@/application/components/Loader";
export default {
  screen_name: "behaviour-create",
  components: { BehaviourForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initBehaviourCreate: null
      },
      behaviour: {
        genoset: null,
        genotype: null,
        summaryEnUs: "",
        summaryPtBr: "",
        summaryEsEs: "",
        isPublished: false,
        repute: 0,
        type: "d248c55a-039b-4051-81e7-eb72849ad59f"
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(BehaviourCreateScreenController);

    //{ INTERACTORS
    this.interactors.initBehaviourCreate = this.$injector.get(
      InitBehaviourCreateInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initBehaviourCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.behaviour);
    }
  }
};
</script>

<style lang="scss"></style>
